import React, { RefObject } from 'react';
import { Spacer } from '@rmstransactions/components';
import NotifyCustomer from './NotifyCustomer';
import {
  LicenceStatusDetails,
  LicenceStatusDetailsDualClass,
} from 'utils/helpers/ErrorHandlers/apiErrorCheck';
import { InPageAlert } from '@snsw/react-component-library';
import { Link } from 'react-router-dom';
import {
  handleScrollToElement,
  hasActiveAndStatus,
  uniqBy,
} from 'utils/helpers/helpers';
import { ILicenceCategoryLicenceApi } from 'interfaces/api/response/ILicence';
import {
  DualClassDescriptionErrorAndPendingCanOrSusp,
  DualClassDescriptionErrorAndRefusalOrSanction,
  DualClassHasActiveAndPendingSuspOrCanc,
  DualClassHasActiveAndSanctionOrRefusalPresent,
  refusalOrSanctionWithExpiringLicenceTitle,
  refusalOrSanctionWithExpiringLicenceDescription
} from 'utils/helpers/ErrorHandlers/helpers/errorContentDetails';
 import { useLicenceExpiry} from '../components/useLicenceExpiry';

const pageValidationDescription = (
  noReason: boolean,
  noConcession: boolean,
  concessionRef: RefObject<HTMLDivElement>,
  licenceRef: RefObject<HTMLDivElement>
) => {
  return (
    <>
      <p>
        Please check the following{' '}
        {noReason && noConcession ? '2 items:' : 'item:'}
      </p>
      <ul>
        {noReason && (
          <li>
            <Link
              to='#What_happened_to_your_NSW_driver_licence_card'
              onClick={() => {
                handleScrollToElement(licenceRef.current);
              }}
            >
              What happened to your NSW Driver Licence card?
            </Link>
          </li>
        )}
        {noConcession && (
          <li>
            <Link
              to='#concessionCardRadio1'
              onClick={() => {
                handleScrollToElement(concessionRef.current);
              }}
            >
              Do you hold a NSW Pensioner Concession Card?
            </Link>
          </li>
        )}
      </ul>
    </>
  );
};

export const NotifyWarningsAndErrors: React.FC<{
  warningCodes: string[] | null;
  errorCodes: string[] | null;
  noReasonSelection: boolean;
  noConcessionSelection: boolean;
  concessionRef: RefObject<HTMLDivElement>;
  licenceRef: RefObject<HTMLDivElement>;
  licenceCategoryEligibility: ILicenceCategoryLicenceApi[];
}> = ({
  warningCodes,
  errorCodes,
  noReasonSelection,
  noConcessionSelection,
  concessionRef,
  licenceRef,
  licenceCategoryEligibility,
}) => {
  const hasActiveAndErrorInDualClass =
    licenceCategoryEligibility &&
    hasActiveAndStatus(licenceCategoryEligibility, 'ERROR');

  const hasActiveAndWarningInDualClass =
    licenceCategoryEligibility &&
    hasActiveAndStatus(licenceCategoryEligibility, 'WARNING');

  let hasErrorAndPendingInDualClass: boolean;
  let hasErrorAndRefusalOrSanctionInDualClass: boolean;
  let hasActiveAndSanctionOrRefusalPresentInDualClass: boolean;
  let hasActiveAndPendingSuspOrCancInDualClass: boolean;
  let hasRefusalOrSanctionWithExpiringLicence: boolean;
  if (
    hasActiveAndWarningInDualClass &&
    warningCodes.length > 0 &&
    (warningCodes?.includes('ACTIVE_REFUSAL_PRESENT') ||
      warningCodes?.includes('ACTIVE_SANCTION_EXISTS'))
  ) {
    hasActiveAndSanctionOrRefusalPresentInDualClass = true;
  }

  if (
    hasActiveAndWarningInDualClass &&
    warningCodes.length > 0 &&
    (warningCodes?.includes('LIC_PENDING_SUSPENSION') ||
      warningCodes?.includes('LIC_PENDING_CANC'))
  ) {
    hasActiveAndPendingSuspOrCancInDualClass = true;
  }

  if (
    (errorCodes?.includes('LIC_STATUS_DISQUALIFIED') ||
      errorCodes?.includes('LIC_STATUS_CANCELLED') ||
      errorCodes?.includes('LIC_STATUS_SUSPENDED')) &&
    (warningCodes?.includes('LIC_PENDING_SUSPENSION') ||
      warningCodes?.includes('LIC_PENDING_CANC'))
  ) {
    hasErrorAndPendingInDualClass = true;
  }

  if (
    (errorCodes?.includes('LIC_STATUS_DISQUALIFIED') ||
      errorCodes?.includes('LIC_STATUS_CANCELLED') ||
      errorCodes?.includes('LIC_STATUS_SUSPENDED')) &&
    (warningCodes?.includes('ACTIVE_REFUSAL_PRESENT') ||
      warningCodes?.includes('ACTIVE_SANCTION_EXISTS'))
  ) {
    hasErrorAndRefusalOrSanctionInDualClass = true;
  }

  const errorStatus =
    errorCodes &&
    LicenceStatusDetails[errorCodes[0]]?.statusLabel?.toLowerCase();

  const warningStatus =
    warningCodes &&
    LicenceStatusDetails[warningCodes[0]]?.statusLabel?.toLowerCase();

  const newWarningCodes = warningCodes && uniqBy(warningCodes, JSON.stringify);

  const dualClassWarningMessage = () => {
    if (hasErrorAndPendingInDualClass) {
      return (
        <Spacer mb={3.5}>
          <NotifyCustomer
            title={`One of your licence classes is ${errorStatus} and the other is pending ${warningStatus}`}
            details={DualClassDescriptionErrorAndPendingCanOrSusp(errorStatus)}
          />
        </Spacer>
      );
    } else if (hasErrorAndRefusalOrSanctionInDualClass) {
      return (
        <Spacer mb={3.5}>
          <NotifyCustomer
            title={`One of your licence classes is ${errorStatus} and the other is active but subject to a restriction`}
            details={DualClassDescriptionErrorAndRefusalOrSanction(
              errorStatus,
              errorStatus
            )}
          />
        </Spacer>
      );
    } else if (hasActiveAndPendingSuspOrCancInDualClass) {
      return (
        <Spacer mb={3.5}>
          <NotifyCustomer
            title={`One of your licence classes is active and the other is pending ${warningStatus}`}
            details={DualClassHasActiveAndPendingSuspOrCanc}
          />
        </Spacer>
      );
    } else if (hasActiveAndSanctionOrRefusalPresentInDualClass) {
      return (
        <Spacer mb={3.5}>
          <NotifyCustomer
            title={`One of your licence classes has a restriction on it`}
            details={DualClassHasActiveAndSanctionOrRefusalPresent}
          />
        </Spacer>
      );
    } else {
      return (
        newWarningCodes && (
          <Spacer mb={3.5}>
            {newWarningCodes.map((code, index) => (
              <NotifyCustomer
                key={index}
                title={LicenceStatusDetails[code]?.title || 'Warning'}
                details={LicenceStatusDetails[code]?.description || code}
              />
            ))}
          </Spacer>
        )
      );
    }
  };

  const handleWarningMessage = () => {
    const expiryDate = useLicenceExpiry(); 
    // single class
    if (licenceCategoryEligibility?.length === 1) {
      if (errorCodes?.length === 1) {
        //redirect to error page
      } else {
        if (  
          (newWarningCodes.includes('DRIVING_LIC_DUE_FOR_EXP') &&
          (newWarningCodes.includes('ACTIVE_REFUSAL_PRESENT') ||
          newWarningCodes.includes('ACTIVE_SANCTION_EXISTS')))
        ) {
          hasRefusalOrSanctionWithExpiringLicence = true;
        }
        return (
        <>
        { !hasRefusalOrSanctionWithExpiringLicence ?
           <Spacer mb={3.5}>
           {newWarningCodes.map((code, index) => (
             <NotifyCustomer
               key={index}
               title={LicenceStatusDetails[code]?.title || 'Warning'}
               details={LicenceStatusDetails[code]?.description || code}
             />
           ))}
         </Spacer>
          :
          <Spacer mb={3.5}>
          <NotifyCustomer
            title={refusalOrSanctionWithExpiringLicenceTitle}
            details={refusalOrSanctionWithExpiringLicenceDescription(expiryDate)}
          />
        </Spacer>
      }
      </>
        );
      }
    } else {
      ///dual class error combination
      if (hasActiveAndErrorInDualClass) {
        return (
          <Spacer mb={3.5}>
            <NotifyCustomer
              title={
                LicenceStatusDetailsDualClass[errorCodes[0]]?.title ||
                LicenceStatusDetails[errorCodes[0]]?.title ||
                'Error'
              }
              details={
                LicenceStatusDetailsDualClass[errorCodes[0]]?.description ||
                LicenceStatusDetails[errorCodes[0]]?.description ||
                errorCodes[0]
              }
            />
          </Spacer>
        );
      } else {
        // dual class warning combination
        return dualClassWarningMessage();
      }
    }
  };

  return (
    <>
      {handleWarningMessage()}

      {(noReasonSelection || noConcessionSelection) && (
        <Spacer mb={3.5}>
          <InPageAlert variant='error' title={'Your page has errors'}>
            {pageValidationDescription(
              noReasonSelection,
              noConcessionSelection,
              concessionRef,
              licenceRef
            )}
          </InPageAlert>
        </Spacer>
      )}
    </>
  );
};
