import { UserSession, helpers } from '@rmstransactions/components';
import {
  DRUPAL_FIRST_PAGE_URL,
  InputValidationErrors,
  MYACCOUNT_LOGIN_URL,
  REPLACE_LICENCE_URL,
  silentAuthUrl,
} from 'constants/constants';
import { ILicenceCategoryLicenceApi } from 'interfaces/api/response/ILicence';
import { logoutUrl } from '@rmstransactions/components/src/MyAccountNav/MyAccountNav';
import { forceLogout } from 'services/ReplaceLicenceService';

export const invalidInputValueCharacters = (value: string): boolean => {
  return Boolean(value.match(/[^a-zA-Z0-9]/g));
};

export const capitalise = (reason: string): string => {
  return reason
    ? reason.charAt(0).toUpperCase() + reason.slice(1).toLowerCase()
    : '';
};

export const validateInputValue = (
  inputValue: string
): InputValidationErrors | null => {
  if (inputValue.length > 12) {
    return InputValidationErrors.MAX_LENGTH_REACHED;
  }
  if (invalidInputValueCharacters(inputValue)) {
    return InputValidationErrors.SPECIAL_CHARACTER;
  }
  if (inputValue.length === 0) {
    return InputValidationErrors.EMPTY_CONCESSION_CARD_NOT_ALLOWED;
  }
  return null;
};

export const getValidatedDate = (
  paramDate: string | number | Date | unknown
): string => {
  try {
    let convertDate;
    if ((paramDate as any).year) {
      // Check if it is coming as an object
      convertDate = `${(paramDate as any).year} ${(paramDate as any).month} ${
        (paramDate as any).dayOfMonth
      }`;
    } else if (
      typeof paramDate == 'string' ||
      typeof paramDate == 'number' ||
      paramDate instanceof Date
    ) {
      convertDate = paramDate;
    } else {
      throw Error('Invalid date format');
    }
    return isNaN(new Date(convertDate).getTime())
      ? ''
      : helpers.dateFormat(new Date(convertDate), 'D MMMM YYYY');
  } catch (error) {
    return '';
  }
};

//filter through array and return the new array with remving duplication
export const uniqBy = <T>(a: T[], key: (item: T) => string): T[] => {
  const seen = new Set<string>();
  return a.filter((item) => {
    const k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
};

export interface RoutePathParams {
  receiptUUID?: string;
  errorCode?: string;
}

export const isExpired = (expiryDt: string): boolean => {
  if (!expiryDt) return false;
  const expiryCutoff = new Date(expiryDt).setHours(23, 59, 0, 0);

  return expiryCutoff <= Date.now();
};

export const hasInactiveLicence = (
  licenceCategory: ILicenceCategoryLicenceApi[]
): boolean => {
  return licenceCategory.some(
    (category: ILicenceCategoryLicenceApi) =>
      category.licenceStatus !== 'Active' || isExpired(category.expiryDate)
  );
};


export const getLicenceExpiry = (
  licenceCategory: ILicenceCategoryLicenceApi[]
): string => {
  const expiryDate = licenceCategory?.[0]?.expiryDate || '';
  return getValidatedDate(expiryDate);
};

export const hasAppealedSuspensionCancelExists = (
  licenceCategory: ILicenceCategoryLicenceApi[]
): boolean => {
  return licenceCategory.some((category) => {
    const { systemMessages } = category;
    return (
      systemMessages &&
      (systemMessages.message[0].identifier === 'APPEALED_SUSPENSION_PRESENT' ||
        systemMessages.message[0].identifier === 'APPEALED_CANC_PRESENT')
    );
  });
};

export const getDisplayLicenceStatus = (
  isExpired: boolean,
  currentStatus: string
): string => {
  return isExpired && currentStatus?.toUpperCase() === 'ACTIVE'
    ? 'Expired'
    : currentStatus;
};

export const getInactiveLicenceCategory = (
  licenceCategory: ILicenceCategoryLicenceApi[]
): ILicenceCategoryLicenceApi => {
  for (const category of licenceCategory)
    if (category.licenceStatus !== 'Active' || isExpired(category.expiryDate))
      return category;
};

export const hasDualClass = (
  licenceCategory: ILicenceCategoryLicenceApi[]
): boolean => {
  return licenceCategory.length > 1;
};

export const handleScrollToElement = (ref: HTMLDivElement | null): void => {
  window.scrollTo({
    top: ref.offsetTop,
    left: 0,
    behavior: 'smooth',
  });
};

export const hasActiveAndStatus = (
  licenceCategory: ILicenceCategoryLicenceApi[],
  statusToCheck: string
): boolean => {
  let hasActive = false;
  let hasStatusToCheck = false;
  let hasNullCategory = false;

  licenceCategory?.forEach((category) => {
    category.systemMessages?.message?.forEach((msg) => {
      if (msg.severity === 'SUCCESS') {
        hasActive = true;
      } else if (msg.severity === statusToCheck) {
        hasStatusToCheck = true;
      }
    });

    if (!category.systemMessages || !category.systemMessages.message) {
      hasNullCategory = true;
    }
  });

  // Check the conditions for returning true
  return (
    (hasActive && hasStatusToCheck) || (hasNullCategory && hasStatusToCheck)
  );
};

export const sortByLicenceType = (
  items: ILicenceCategoryLicenceApi[]
): ILicenceCategoryLicenceApi[] => {
  return items.slice().sort((itemA, itemB) => {
    const licenceTypeOrder = ['U', '2', '1', 'L'];

    const codeA = itemA.licenceType.code;
    const codeB = itemB.licenceType.code;

    const indexA = licenceTypeOrder.indexOf(codeA);
    const indexB = licenceTypeOrder.indexOf(codeB);

    if (indexA !== -1 && indexB !== -1) {
      if (codeA === codeB) {
        // Compare licenceClass.code,'R' comes last
        if (itemA.licenceClass.code !== 'R' && itemB.licenceClass.code === 'R')
          return -1;
        if (itemA.licenceClass.code === 'R' && itemB.licenceClass.code !== 'R')
          return 1;
      }
      return indexA - indexB;
    }

    if (indexA !== -1) {
      return -1;
    }

    if (indexB !== -1) {
      return 1;
    }

    return 0;
  });
};

export const handleScrollToTop = (): void => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, 500);
};

export const invokeSilentLoginIfSessionNotActive = (): void => {
  if (!UserSession.hasActiveSession()) {
    UserSession.setSilentLoginCalled();
    UserSession.silentLogin(silentAuthUrl);
  }
};

export const pageUrl = (prefix: string): string => {
  return `${window.location.origin}/${prefix}`;
};

export const transactionStartTime = (): Date => new Date();

export const addSpacesAfterCommas = (inputString: string): string => {
  return inputString?.replace(/,(?!\s)/g, ', ');
};

export const licenceTypeList = {
  U: { code: 'Full', description: 'Unrestricted' },
  1: { code: 'P1', description: 'Provisional' },
  2: { code: 'P2', description: 'Provisional' },
  L: { code: 'L', description: 'Learner' },
};

export const logoutAndResetUserSession = (): Promise<void> => {
  return forceLogout().finally(() => {
    UserSession.resetLoggedInUser();
    UserSession.resetActiveSession();
    sessionStorage.clear();
    UserSession.setLoggedOutUser();
  });
};

export const clickHandlerToLogoutLink = (): void => {
  const link = document.querySelector(`a[href='${logoutUrl}']`);
  if (link) {
    link.addEventListener('click', () => {
      logoutAndResetUserSession();
    });
  }
};

export const goToLogin = async (): Promise<void> => {
  UserSession.login(MYACCOUNT_LOGIN_URL);
};

export const gotoDrupal = (): void => {
  window.location.href = REPLACE_LICENCE_URL;
};

export const goToDrupalFirstPage = (): void => {
  window.location.href = DRUPAL_FIRST_PAGE_URL;
};

export const minutesToMilliseconds = (minutes: number): number => {
  const millisecondsPerMinute: number = 60 * 1000; // One minute in milliseconds
  return minutes * millisecondsPerMinute;
};
